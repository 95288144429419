<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <!--          <el-form-item label="">-->
          <!--            <el-select v-model="filters.areaStr" placeholder="请选择省份" filterable clearable>-->
          <!--              <el-option-->
          <!--                  v-for="item in areaArr"-->
          <!--                  :key="item.id"-->
          <!--                  :label="item.name"-->
          <!--                  :value="item.code"-->
          <!--              >-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
    >
      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="sort" label="排序"  align="center" ></el-table-column>
      <el-table-column prop="professionDirName" label="名称"  align="center" ></el-table-column>
      <el-table-column prop="remark" label="简称"  align="center" ></el-table-column>
      <el-table-column prop="ddIdExamSeatStatus" label="状态"  align="center" >
        <template #default='scope'>
          <el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>启用</el-tag>
          <el-tag v-else type='warning'>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间"  align="center" >
<!--        <template #default="scope">-->
<!--          {{scope.row.createTime.split('T')[0]}}&nbsp;{{scope.row.createTime.split('T')[1].split('.')[0]}}-->
<!--        </template>-->
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <el-dialog
        :title= "editFalse?'编辑':'添加职业等级方向'"
        :visible.sync="dialogs.addType.dialog"
        width="30%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="100px">
        <el-form-item label="职业功能：">
          <el-input v-model="addForm.profession" style="width: 80%"></el-input>
        </el-form-item>
	      <el-form-item label="简称：">
		      <el-input v-model="addForm.remark" style="width: 80%"></el-input>
	      </el-form-item>
	      <el-form-item label="排序：">
		      <el-input v-model="addForm.sort" style="width: 80%"></el-input>
	      </el-form-item>
        <el-form-item label='状态：' prop='webUrl'>
          <template>
            <el-radio-group v-model="addForm.radioAdd">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
      </el-form>
      <span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
      <span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="showConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
    <el-dialog
        title= "提示"
        :visible.sync="dialogs.addType.confirm"
        width="30%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="100px">
        <el-form-item label="职业功能：">
          {{'该分类下的数据将停止使用”，确定禁用吗？'}}
        </el-form-item>
      </el-form>
      <span  slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.confirm = false">取 消</el-button>
                <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
  addOproomProfessionDir,
  getAreaDictionaryList, getOproomProfessionDirList, getOproomProfessionFuncList, updateOproomProfessionDir,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {
        keyWord: '',
        areaStr: '',
        entyType: '',
      },
      dialogs:{
        addType:{
          dialog:false,
          confirm:false,
        },
        confirm:false,
      },
      addForm:{
        profession:undefined,
        radioAdd:1,
	      remark:undefined,
	      sort:undefined,
      },
      loading:false,
      addFormRules:[],
      professionType: [],
      editFalse:false,


    }
  },
  methods: {
    handleAdd(){
      this.addForm={
        profession:undefined,
        radioAdd:1,
	      remark:undefined,
	      sort:undefined,
      }
      this.editFalse = false
      this.dialogs.addType.dialog = true;
    },
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    getDataList(){
      this.pages = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.getListData()
    },
    // 列表查询接口
    getListData(){
      var params = {
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
      }
      this.listLoading = true
      getOproomProfessionDirList(params).then(res => {
        var result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
        }
        this.listLoading = false
      })
    },
    async addConfirm(){
      let params = {
        ddCommonStatus:this.addForm.radioAdd,
        id:undefined,
        professionDirName:this.addForm.profession,
	      remark:this.addForm.remark,
	      sort:this.addForm.sort
      }
      const res = await addOproomProfessionDir(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },
    handleEdit(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.editFalse = true;
      this.addForm = {
        profession: this.needData.professionDirName,
        radioAdd: this.needData.ddCommonStatus,
	      remark: this.needData.remark,
	      sort: this.needData.sort
      }
      this.dialogs.addType.dialog = true;
    },
    showConfirm() {
      if (this.addForm.radioAdd === 2){
        this.dialogs.addType.confirm = true
      }else {
        this.editConfirm()
      }
    },
    async editConfirm(){
      let params = {
        ddCommonStatus:this.addForm.radioAdd,
        id:this.needData.id,
        professionDirName:this.addForm.profession,
	      remark:this.addForm.remark,
	      sort:this.addForm.sort
      }
      const res = await updateOproomProfessionDir(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.dialogs.addType.confirm = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
      this.editFalse = false;
    },




    // 获取省
    getAreaList() {
      var params = {
        parentId: 1,
      }
      getAreaDictionaryList(params).then(res => {
        if (res.data.success) {
          this.areaArr = res.data.response.reverse();
        }
      })
    },
    //获取字符空间
    async getNamespace(){
      let params = {
        pageIndex:undefined,
        pageSize:undefined,
      }
      const res = await  getByNamespace(params)
      console.log(res,'res')
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      console.log(val,'val')
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getListData();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getListData();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {

  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getListData();
    this.getAreaList()
  },
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
